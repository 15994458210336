<template>
  <div class="container">
    <template v-if="user">
      <div class="profile-header text-center">
        <h5><b>Perfil de Usuario</b></h5>
      </div>
      <div class="d-flex m-2 w-100 card-container">
        <div class="img-container">
          <!-- <img src="@/assets/user-icon.jpg" class="user-img" /> -->
          <template v-if="user && user.user_data && user.user_data.image">
            <ImageEditor
              :key="user_image_key"
              :display_options="false"
              :portrait_size="true"
              :image_id="user.user_data.image"
              :allows_crud="allows_crud"
              @deleted="slotImageDeleted"
            ></ImageEditor>
          </template>
          <template v-else>
            <div class="profile-img-default mb-2">
              <b-icon
                class="default-user-img"
                icon="person-square"
                variant="secondary"
                scale="6"
              ></b-icon>
            </div>
          </template>
          <div class="d-block">
            <b-button
              v-if="is_owner"
              @click="$bvModal.show('modal-select-image')"
              size="sm"
              class="btn-image-upload mt-2"
              >{{
                user && user.user_data && user.user_data.image
                  ? "Cambiar Foto de Perfil"
                  : "Seleccionar Foto de Perfil"
              }}</b-button
            >
            <!-- v-b-tooltip.v-secondary.noninteractive="'Cambiar contraseña'" -->
            <b-button
              class="btn-change-password"
              size="sm"
              @click="$bvModal.show(`modal-update-password-${user.id}`)"
              ><b-icon
                icon="arrow-counterclockwise"
                class="counter-clockwise-icon"
                scale="1.5"
              ></b-icon
              ><b-icon icon="lock-fill" class="lock-icon" scale="0.6"></b-icon
              >Actualizar Contraseña</b-button
            >
          </div>
        </div>
        <div class="user-card-info mt-2">
          <div class="m-1 personal-info-1">
            <div class="info-item mb-1">APELLIDOS</div>
            <div v-if="allows_crud">
              <b-form-input v-model="user.last_name" size="sm"></b-form-input>
            </div>
            <div v-else>{{ user.last_name ? user.last_name : "-" }}</div>
            <div class="info-item mb-1 mt-2">NOMBRES</div>
            <div v-if="allows_crud">
              <b-form-input v-model="user.first_name" size="sm"></b-form-input>
            </div>
            <div v-else>{{ user.first_name ? user.first_name : "-" }}</div>
            <template
              v-if="
                user.user_data && identifier_types.length > 0 && allows_crud
              "
            >
              <div class="info-item mb-1 mt-2">Tipo de Identificador:</div>
              <div>
                <b-form-select
                  :id="`input-identifier_type`"
                  :options="identifier_types"
                  value-field="id"
                  text-field="identifier_name"
                  v-model="user.user_data.identifier_type"
                ></b-form-select>
              </div>
            </template>
            <template
              v-if="
                user.user_data && identifier_types.length > 0 && allows_crud
              "
            >
              <div class="info-item mb-1 mt-2">Identificador:</div>
              <div>
                <b-form-input
                  :id="`textarea-identifier`"
                  v-model="user.user_data.identifier"
                  class="m-0"
                ></b-form-input>
              </div>
            </template>
          </div>
          <b-modal
            :id="`modal-update-password-${user.id}`"
            title="Actualizar Contraseña"
            size="lg"
            hide-footer
          >
            Contraseña actual
            <div class="d-flex">
              <div class="d-block w-100">
                <b-form-input
                  v-model="$v.current_password.$model"
                  :state="validateCurrentPassword('current_password')"
                  :type="field_type1"
                  :class="{ 'my-2': !current_password_visibility }"
                  autocomplete="off"
                  aria-describedby="current-password-feedback"
                ></b-form-input
                ><b-form-invalid-feedback
                  class="invalid-feedback"
                  id="current-password-feedback"
                  >Debe ingresar su contraseña actual.</b-form-invalid-feedback
                >
              </div>
              <b-button class="password-visibility" @click="currentPassword"
                ><b-icon v-if="current_password_visibility" icon="eye"></b-icon
                ><b-icon v-else icon="eye-slash"></b-icon
              ></b-button>
            </div>
            Nueva contraseña
            <div class="d-flex">
              <b-form-input
                v-model="$v.new_password.$model"
                :type="field_type2"
                :state="validateNewPassword()"
                :class="{ 'my-2': !new_password_visibility }"
                v-b-tooltip.v-secondary.noninteractive="
                  'Se recomienda utilizar una combinación de mayúsculas, minúsculas, números y símbolos para mayor seguridad. '
                "
              ></b-form-input
              ><b-button class="password-visibility" @click="newPassword"
                ><b-icon v-if="new_password_visibility" icon="eye"></b-icon
                ><b-icon v-else icon="eye-slash"></b-icon
              ></b-button>
            </div>
            Confirmar contraseña
            <div class="d-flex">
              <b-form-input
                v-model="$v.confirm_password.$model"
                :state="validateConfirmPassword()"
                :type="field_type3"
                :class="{ 'my-2': !confirm_password_visibility }"
              ></b-form-input
              ><b-button class="password-visibility" @click="confirmPassword"
                ><b-icon v-if="confirm_password_visibility" icon="eye"></b-icon
                ><b-icon v-else icon="eye-slash"></b-icon
              ></b-button>
            </div>
            <span
              class="password-incorrect"
              v-if="new_password != confirm_password && confirm_password != ''"
            >
              <b-icon icon="exclamation-circle"> </b-icon>
              Las contraseñas no coinciden.
            </span>
            <div class="save-password-container mt-4">
              <b-button
                class="save-password-btn"
                size="sm"
                @click="savePassword"
              >
                Guardar
              </b-button>
            </div>
            <!-- @stateChanged="slotStateChanged" -->
          </b-modal>
          <div class="m-1 personal-info-2">
            <div class="info-item mb-2">CORREO</div>
            <div v-if="allows_crud" class="email-input">
              <b-form-input
                v-model="user.email"
                size="sm"
                type="email"
              ></b-form-input>
            </div>
            <div v-else>{{ user.email ? user.email : "" }}</div>
            <div class="info-item mb-1 custom-mt">GÉNERO</div>
            <div v-if="allows_crud" class="gender-select">
              <b-form-select
                v-model="user.gender"
                size="sm"
                :options="genders"
                value-field="id"
                text-field="value"
              ></b-form-select>
            </div>
            <div v-else>
              {{ user.gender ? genders[user.gender].value : "-" }}
            </div>
            <div class="info-item mb-1 mt-3">FECHA DE NACIMIENTO</div>
            <div v-if="allows_crud" class="date-birth-select">
              <b-form-datepicker
                v-model="user.birth_date"
                size="sm"
                placeholder="Seleccione una fecha"
              ></b-form-datepicker>
            </div>
            <div v-else>{{ user.birth_date ? user.birth_date : "-" }}</div>
          </div>
        </div>
      </div>
      <div v-if="allows_crud">
        <b-button size="sm" @click="save">Guardar</b-button>
      </div>
    </template>
    <!-- Modals -->
    <b-modal
      id="modal-select-image"
      title="Seleccionar Imagen"
      hide-footer
      size="md"
    >
      <ImageSelector @created="slotImageCreated"></ImageSelector>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  maxValue,
  sameAs,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "StudentCardComponent",
  mixins: [validationMixin],
  props: {
    User: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImageEditor: () => import("@/components/reusable/ImageEditor"),
    ImageSelector: () => import("@/components/reusable/ImageSelector"),
  },
  data() {
    return {
      user: this.User,
      student_card: {
        id: generateUniqueId(),
        user: this.User.id,
        cohort: null,
        high_school_grade: null,
        psu_average: null,
        psu_year: null,
        campus: null,
        career: null,
        egress_profile: null,
        faculty: null,
        shift: null,
        admission_profile: null,
        admission_profile_finished: false,
      },
      genders: [
        { id: 0, value: "Masculino" },
        { id: 1, value: "Femenino" },
        { id: 2, value: "Otro" },
      ],
      admission_profiles: [],
      allows_crud: false,
      is_owner: false,
      identifier_types: [],
      current_password: "",
      new_password: "",
      confirm_password: "",
      current_password_visibility: false,
      new_password_visibility: false,
      confirm_password_visibility: false,
      field_type1: "password",
      field_type2: "password",
      field_type3: "password",
      user_image_key: 0,
    };
  },
  validations() {
    return {
      student_card: {
        cohort: { minValue: minValue(1990), maxValue: maxValue(2100) },
        psu_year: { minValue: minValue(1990), maxValue: maxValue(2100) },
      },
      current_password: { required },
      new_password: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[.#?!@$%^&*-]/.test(value);
        },
      },
      confirm_password: { required, sameAsPassword: sameAs("new_password") },
    };
  },
  computed: {
    ...mapGetters({
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
      shifts: names.SHIFTS,
      loged_user: "getUser",
    }),
    campus() {
      const campus = this.campuses.find(
        (x) => x.id == this.student_card.campus
      );
      return campus ? campus.name : "-";
    },
    faculty() {
      const faculty = this.faculties.find(
        (x) => x.id == this.student_card.faculty
      );
      return faculty ? faculty.name : "-";
    },
    filteredFaculties() {
      let list = [];
      if (this.student_card.campus) {
        this.faculties.forEach((faculty) => {
          if (faculty.campuses.find((x) => x == this.student_card.campus))
            list.push(faculty);
        });
        return list;
      }
      return this.faculties;
    },
    career() {
      const career = this.careers.find((x) => x.id == this.student_card.career);
      return career ? career.name : "-";
    },
    filteredCareers() {
      if (this.student_card.faculty)
        return this.careers.filter(
          (x) => x.faculty == this.student_card.faculty
        );
      return this.careers;
    },
    egressProfile() {
      const egress_profile = this.egressProfiles.find(
        (x) => x.id == this.student_card.egress_profile
      );
      return egress_profile ? egress_profile.name : "-";
    },
    filteredEgressProfiles() {
      if (this.student_card.career)
        return this.egressProfiles.filter(
          (x) => x.career == this.student_card.career
        );
      return this.egressProfiles;
    },
    shift() {
      const shift = this.shifts.find((x) => x.id == this.student_card.shift);
      return shift ? shift.name : "-";
    },
    admissionProfile() {
      if (this.student_card.admission_profile) {
        const admission_profile = this.admission_profiles.find(
          (x) => x.id == this.student_card.admission_profile
        );
        if (admission_profile) return admission_profile;
      }
      return null;
    },
    admissionProfileName() {
      if (this.admissionProfile) return this.admissionProfile.name;
      return "-";
    },
  },
  methods: {
    validateNewPassword() {
      const { $dirty, $error } = this.$v.new_password;
      return $dirty ? !$error : null;
    },
    validateConfirmPassword() {
      const { $dirty, $error } = this.$v.confirm_password;
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.student_card[name];
      return $dirty ? !$error : null;
    },
    validateCurrentPassword() {
      const { $dirty, $error } = this.$v.current_password;
      return $dirty ? !$error : null;
    },
    save() {
      if (this.user.user_data) {
        const item = {
          user: this.user.id,
          identifier_type: this.user.user_data.identifier_type,
          identifier: this.user.user_data.identifier,
          image: this.user.user_data.image,
        };
        this.saveUserData(item);
      }
      this.$v.student_card.$touch();
      if (this.$v.student_card.$anyError) {
        return;
      }
      if (isNaN(this.student_card.id)) this.createStudentCard();
      else this.updateStudentCard();
    },
    createStudentCard() {
      this.$restful
        .Post(`/cideu/student-card/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          toast("Perfil creado.");
        });
    },
    updateStudentCard() {
      this.$restful
        .Put(`/cideu/student-card/${this.student_card.id}/`, this.student_card)
        .then((response) => {
          this.student_card = response;
          toast("Perfil actualizado.");
        });
    },
    slotImageCreated(image) {
      let new_user_data = null;
      let item = null;
      if (this.user.user_data) {
        new_user_data = this.user.user_data;
        if (image) {
          new_user_data.image = image.id;
          item = {
            image: new_user_data.image,
          };
        }
      } else {
        if (image) {
          new_user_data = {
            image: image.id,
            user: this.user.id,
          };
          item = {
            image: new_user_data.image,
            user: new_user_data.user,
            identifier_type: null,
          };
        }
      }
      // Se quita condición correspondiente a estructura antigua de user (ahora se cambia desde user-data)
      // if (this.user.user_data.image) {
      //   try {
      //     const image_id = this.user.user_data.image;
      //     this.patchUserData(item);
      //     this.$store.dispatch(names.DELETE_IMAGE, image_id);
      //   } catch (error) {
      //     this.patchUserData(item);
      //   }
      // }
      // else {
      //   console.log(item);
      // }
      if (item) {
        this.saveUserData(item);
      }
    },
    saveUserData(item) {
      this.user_image_key += 1;
      const payload = {
        item: item,
      };
      if (this.user.user_data) {
        this.$restful
          .Patch(
            `/authentication/user-data/${this.user.user_data.id}/`,
            payload.item
          )
          .then((response) => {
            this.user.user_data.image = response.image;
            this.$store.commit("setUser", this.user);
          });
      } else {
        this.$restful
          .Post(`/authentication/user-data/`, payload.item)
          .then((response) => {
            this.user.user_data = response;
            this.$store.commit("setUser", this.user);
          });
      }
      // this.$store.dispatch(names.PATCH_USER, payload).then((response) => {
      //   this.$store.commit('setUser', response);
      //   if (response) this.user = response;
      //   toast("Usuario actualizado.");
      // });
      this.$bvModal.hide("modal-select-image");
    },
    slotImageDeleted() {
      const item = {
        image: null,
      };
      this.$restful
        .Patch(`/authentication/user-data/${this.user.user_data.id}/`, item)
        .then((response) => {
          this.user.user_data.image = response.image;
          this.$store.commit("setUser", this.user);
        });
    },
    fetchStudentCard() {
      this.$restful
        .Get(`/cideu/student-card/?user=${this.user.id}`)
        .then((response) => {
          if (response.length > 0) {
            this.student_card = response[0];
            if (this.student_card.campus)
              this.$store.dispatch(
                names.FETCH_CAMPUSES,
                this.student_card.campus
              );
            if (this.student_card.faculty)
              this.$store.dispatch(
                names.FETCH_FACULTY,
                this.student_card.faculty
              );
            if (this.student_card.career)
              this.$store.dispatch(
                names.FETCH_CAREER,
                this.student_card.career
              );
            if (this.student_card.egress_profile)
              this.$store.dispatch(
                names.FETCH_EGRESS_PROFILE,
                this.student_card.egress_profile
              );
            if (this.student_card.shift)
              this.$store.dispatch(names.FETCH_SHIFT, this.student_card.shift);
          }
        });
    },
    fetchAdmissionProfiles() {
      const institution_id = this.$store.getters.getInstitutionId;
      if (!institution_id) return;
      this.$restful
        .Get(`/cideu/admission-profile/?institution=${institution_id}`)
        .then((response) => {
          this.admission_profiles = response;
        });
    },
    currentPassword() {
      this.current_password_visibility = !this.current_password_visibility;
      if (this.current_password_visibility) {
        this.field_type1 = "text";
      } else {
        this.field_type1 = "password";
      }
    },
    newPassword() {
      this.new_password_visibility = !this.new_password_visibility;
      if (this.new_password_visibility) {
        this.field_type2 = "text";
      } else {
        this.field_type2 = "password";
      }
    },
    confirmPassword() {
      this.confirm_password_visibility = !this.confirm_password_visibility;
      if (this.confirm_password_visibility) {
        this.field_type3 = "text";
      } else {
        this.field_type3 = "password";
      }
    },
    savePassword() {
      this.$v.new_password.$touch();
      this.$v.confirm_password.$touch();
      this.$v.current_password.$touch();
      if (
        this.$v.new_password.$anyError ||
        this.$v.confirm_password.$anyError ||
        this.$v.current_password.$anyError
      ) {
        return;
      }
      let payload = {
        old_password: this.current_password,
        new_password: this.new_password,
        repeated_password: this.confirm_password,
      };
      this.$restful
        .Put(`/authentication/password/`, payload)
        .then((response) => {
          if (response) {
            this.$bvModal.hide(`modal-update-password-${this.user.id}`);
            setTimeout(() => {
              toast("Contraseña actualizada con éxito.");
            }, 300);
          }
        })
        .catch(() => {
          return this.$swal({
            text: "La contraseña actual es incorrecta.",
            type: "warning",
          });
        });
    },
  },
  created() {
    if (this.loged_user.is_superuser) {
      // this.allows_crud = true;
      this.$store.dispatch(names.FETCH_CAMPUSES);
      this.$store.dispatch(names.FETCH_CAREERS);
      this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
      this.$store.dispatch(names.FETCH_FACULTIES);
      this.$store.dispatch(names.FETCH_SHIFTS);
    }
    if (this.loged_user.id == this.user.id) this.is_owner = true;
    this.$restful.Get(`/common/identifer-type/`).then((response) => {
      this.identifier_types = response;
    });
    this.fetchStudentCard();
    this.fetchAdmissionProfiles();
  },
};
</script>

<style scoped>
.profile-header {
  /* position: absolute; */
  margin: auto;
  width: 50%;
  top: 6%;
  border-bottom: 0.1px solid #7ebacc;
  width: 25rem;
}
.img-container {
  display: block;
  width: 30%;
}
.default-user-img {
  transform: translateY(210%);
}
.info-item {
  font-weight: bold;
  color: rgb(53, 53, 53);
  font-size: 11pt;
  text-align: left;
}
.btn-image-upload,
.btn-change-password {
  width: 70%;
}
.btn-change-password {
  margin-top: 9px;
}
.lock-icon {
  transform: translateX(-50%);
}
.counter-clockwise-icon {
  margin-left: -4%;
  transform: translateX(50%);
}
.save-password-container {
  display: flex;
}
.save-password-btn {
  margin-left: auto !important;
  margin-right: 0 !important;
}
.password-incorrect {
  color: #dc3545 !important;
}
.password-visibility {
  height: 2.35em;
  margin-top: 8px;
  margin-left: 1%;
}
.card-container {
  background-color: var(--primary-hover-color);
  box-shadow: 1px 1px 7px #9cb0b1;
  border-radius: 5px;
  padding: 4rem;
}
.personal-info-1,
.personal-info-2 {
  width: 33%;
  margin-left: 2.8rem !important;
  text-align: left;
}
.invalid-feedback {
  margin-top: -1%;
  margin-bottom: 1%;
}
.profile-img-default {
  width: 5.5em;
  height: 5.5em;
  background: #ddd;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
}
.user-card-info {
  display: flex;
  width: 70%;
}
.email-input,
.gender-select,
.date-birth-select {
  margin-top: 0.7em !important;
}
.custom-mt {
  margin-top: 1.2em;
}
@media (max-width: 780px) {
  .user-card-info {
    display: block;
  }
  .personal-info-1,
  .personal-info-2 {
    width: 85%;
  }
  .btn-image-upload,
  .btn-change-password {
    width: 100%;
  }
}
@media (max-width: 530px) {
  .card-container {
    display: block !important;
    padding: 2em 0.2em;
  }
  .img-container,
  .personal-info-1,
  .personal-info-2 {
    width: 100%;
  }
  .btn-image-upload,
  .btn-change-password {
    width: 70%;
  }
}
</style>